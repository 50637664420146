<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'star filled sharp'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 260 245"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
>
  <path d="m56,237 74-228 74,228L10,96h240" />
</svg>
